<template>
  <v-col
    cols="12"
    md="12"
  >
    <!-- <v-row>
      <v-col cols="5">
        <v-btn
          class="ma-2"
          color="secondary"
          @click="filterUsersList()"
        >
          Все
        </v-btn>

        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click="filterUsersList(3)"
        >
          Курьеры
        </v-btn>

        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click="filterUsersList(2)"
        >
          Операторы
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="actions-bar"
      >
        <v-btn
          class="ma-2"
          color="green"
          @click="createDialog"
        >
          Добавить пользователя
        </v-btn>
      </v-col>
    </v-row> -->

    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Пользователи системы</div>

        <div class="text-subtitle-1 font-weight-light">Общee количество: {{ usersList.length }}</div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="usersList"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editDialog(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              color="red"
              @click="deleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Пользователь</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.name"
                    label="Имя"
                    :error-messages="errors.name"
                    @input="errors.name = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.email"
                    label="Email"
                    :error-messages="errors.email"
                    @input="errors.email = []"
                  />
                </v-col>
                <v-col
                  v-if="actionMethod === 'create'"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.password"
                    label="Пароль"
                    :error-messages="errors.password"
                    @input="errors.password = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.role_id"
                    :items="rolesList"
                    item-text="name"
                    item-value="id"
                    :disabled="actionMethod === 'edit'"
                    label="Роль"
                    :error-messages="errors.role_id"
                    @change="errors.role_id = []"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            v-if="actionMethod === 'edit'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editUser"
          >
            Редактировать
          </v-btn>
          <v-btn
            v-if="actionMethod === 'create'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="createUser"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="danger darken-1"
            text
            @click="deleteUser"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import UserService from '@/app/Services/UserService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import GetUsers from '@/app/Helpers/mixins/essences/getUsers'

export default {
  name: 'UsersDashboard',

  mixins: [TableDialogMixin, GetUsers],

  data() {
    return {
      headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: true,
          text: 'Имя',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Email',
          value: 'email',
        },
        // {
        //   text: 'Действия',
        //   value: 'actions',
        //   sortable: false,
        // },
      ],
      dialogData: {
        name: '',
        email: '',
        role_id: '',
        password: '',
      },
    }
  },

  computed: {
    rolesList() {
      return this.$store.state.references.roles
    },
  },

  async created() {
    this.loading = true

    await this.getUsers()

    this.loading = false
  },

  methods: {
    async createUser() {
      if (this.$refs.form.validate()) {
        const response = await UserService.createUser(this.dialogData)

        if (response.status) {
          this.dialog = false
          await this.getUsers()
        } else {
          this.errors = response.error
        }
      }
    },

    async editUser() {
      if (this.$refs.form.validate()) {
        const response = await UserService.editUser(this.selectedId, this.dialogData)

        if (response.status) {
          this.dialog = false
          await this.getUsers()
        } else {
          this.errors = response.error
        }
      }
    },

    async deleteUser() {
      const response = await UserService.deleteUser(this.selectedId)

      if (response.status) {
        await this.getUsers()
        this.dialogDelete = false
      } else {
        this.errors = response.error
      }
    },

    filterUsersList(id = null) {
      if (id) {
        this.usersList = this.$store.state.user.users.filter((user) => user.role_id === id)
      } else {
        this.usersList = this.$store.state.user.users
      }
    },
  },
}
</script>
