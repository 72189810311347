import api from '@/app/api'

export default {
  async getStatisticsDaily() {
    const response = await api.get('/statistic/daily')
    return response
  },

  async deleteComment(id) {
    const response = await api.delete(`/calls/delete-comment/${id}`)
    return response
  },

  async editComment(id, data) {
    const response = await api.put(`/calls/edit-comment/${id}`, data)
    return response
  },

  async createComment(data) {
    const response = await api.post('/calls/add-comment', data)
    return response
  },

  async getSortedCalls(data) {
    const response = await api.get(`/calls/?${data}`)
    return response
  },
  async getCalls() {
    const response = await api.get('/calls')
    return response
  },
  async getUsers() {
    const response = await api.get('/user')
    return response
  },

  async createUser(userData) {
    const response = await api.post('/user', userData)
    return response
  },

  async getUserById(id) {
    const response = await api.get(`/user/${id}`)
    return response
  },

  async editUser(id, userData) {
    const response = await api.put(`/user/${id}`, userData)
    return response
  },

  async deleteUser(id) {
    const response = await api.delete(`/user/${id}`)
    return response
  },
}
